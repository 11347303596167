import { useState, useEffect } from 'react';
import styled from 'styled-components';
import PriceService from "../../services/PriceService";
import CustomerService from '../../services/CustomerService';
import toThousand from '../../services/ToThousandService';

export default function CalculatingPiles (){

    const [long, setLong] = useState('');
    const [selectedOption, setSelectedOption] = useState(null);
    const [diameter, setDiameter] = useState('');
    const [passed, setPassed] = useState('');
    const [spiral, setSpiral] = useState('');
    const [dolar, setDolar] = useState([]);
    const [showResults, setShowResults] = useState(false);

    useEffect(() => {
        const getDolar = async () => {
            const dolarData = await CustomerService();
            setDolar(dolarData);
        };
        getDolar();
    }, []);

    const price = PriceService.prices

    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const handleLongChange = (event) => {
        const newValue = Math.abs(parseFloat(event.target.value)); // Obtener el valor absoluto
        setLong(newValue);
    };

    const handleDiameterChange = (event) => {
        const newValue = Math.abs(parseFloat(event.target.value)); // Obtener el valor absoluto
        setDiameter(newValue);
    };

    const handlePassedChange = (event) => {
        const newValue = Math.abs(parseFloat(event.target.value)); // Obtener el valor absoluto
        setPassed(newValue);
    };

    const areInputsValid = () => {
        return long !== '' && diameter !== '' && passed !== '' !== null;
    };

    const calculatePiles = () => {
        const longValue = parseFloat(long);
        const diameterValue = parseFloat(diameter);
        const passedValue = parseFloat(passed);
        
        const calc1 = longValue ** 2;
        const calc2 = (3.14 * diameterValue * longValue / passedValue ) ** 2;

        const calc3 = (calc1 + calc2)
        const spiralValue = ( calc3 ** 0.5 );

        setSpiral(spiralValue);
        setShowResults(true);
    };
    
    return (
        <div style={{backgroundColor: 'var(--colorFilaTabla'}}>
            <div className="container-fluid">
                <div className="row" style={{paddingBottom: '5rem', marginTop: '5rem' }}>
                    <StyleCalculatingPiles>
                        <h2 style={{textAlign: 'center'}}>Calculador de solo Espiral </h2>
                        <h2 style={{margin: '1rem 0rem', textAlign: 'center'}}><i class="fa-solid fa-calculator"></i></h2>
                        <div className="containerImputs">
                            <label htmlFor="" className='titleTextResult' style={{fontSize: '1.5rem', fontWeight: '600', color: 'var(--colorTerciario)', marginTop: '1rem'}}>¿Que medida tiene el pilote?</label>

                            <select name="diametro" id="diametro" className="form-select" onChange={handleSelectChange} style={{margin: '1rem 0rem'}}>
                                <option value="" disabled selected>Diámetro de Barton (mm)</option>
                                <option value="1">Diámetro 6</option>
                                <option value="3">Diámetro 8</option>
                                <option value="4">Diámetro 10</option>
                            </select>

                            <input  className="form-control" style={{margin: '1rem 0rem'}} type="number" 
                                    value={long} onChange={handleLongChange} placeholder="Ingrese largo (metros)" />
                            <input  className="form-control" style={{margin: '1rem 0rem'}} type="number"
                                    value={diameter} onChange={handleDiameterChange} placeholder="Ingrese diámetro del Espiral (metros)" />
                            <input  className="form-control" style={{margin: '1rem 0rem'}} type="number"
                                    value={passed} onChange={handlePassedChange} placeholder="Ingrese valor de paso (metros)" />

                            <div style={{marginTop: '1.5rem', textAlign: 'center', marginBottom: '1rem' }}>
                                <button type="button" className="btn btn-primary" 
                                        onClick={calculatePiles} disabled={!areInputsValid()}>Calcular Metros Lineales</button>
                            </div>
                        </div>
                    </StyleCalculatingPiles>

                    {showResults && (
                        <StyleResult>
                            <div className="container">

                                <div className='row'>
                                    <div className="containerTextResult">

                                        <div className="col-12" style={{textAlign: 'center'}}>
                                            <h4 style={{marginBottom: '2rem', color: 'var(--colorTerciario)'}}>Total en Barton {(selectedOption == 1 ? 6 : selectedOption == 3 ? 8 : 10)}</h4>
                                            <p>Total de metros del Espiral: <strong>{toThousand(spiral)} metros lineales</strong></p>
                                            <p>Precio lineal Total Dolar: <strong>{toThousand(((spiral) * price[selectedOption].precioLineal)) } U$S </strong></p>
                                            <p>Precio lineal Total Pesos: <strong>{toThousand(((spiral) * price[selectedOption].precioLineal)*dolar) } $</strong></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </StyleResult>
                    )
                    }
                </div>
            </div>
        </div>
    )
}

const StyleCalculatingPiles = styled.div`
    .containerImputs {
        @media (min-width: 768px) {
            width: 30%;
            margin: 0 auto;
        }
    }
`

const StyleResult = styled.div`
    margin-top: 3rem;
    .containerTextResult{
        border: 1px solid var(--colorFilaTabla);
        border-radius: 0.5rem;
        padding: 2rem 1rem;
        width: 100%;
        background-color: #FFF;
        @media (min-width: 768px) {
            display: flex;
        }
    }
`